<template>
  <b-container>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">
            {{ $route.params.id ? "Update" : "Create" }} Product
          </h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center">
            <b-spinner variant="secondary"></b-spinner>
          </p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-form-group
                :invalid-feedback="
                  validationInvalidFeedback(errors.errors, 'supplier_id')
                "
                label="Supplier"
              >
                <supplier-search
                  v-model="product.supplier"
                  :error-feed="errors.errors"
                  :error-key="'supplier_id'"
                  class="
                    align-self-lg-center
                    mb-2 mb-lg-0
                    mr-lg-1
                    text-nowrap
                    w-100
                  "
                  type="supplier"
                  @input="supplierUpdated"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group
                :invalid-feedback="
                  validationInvalidFeedback(errors.errors, 'name')
                "
                label="Name"
              >
                <b-form-input
                  v-model="product.name"
                  :disabled="isSaving"
                  :state="validationState(errors.errors, 'name')"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                :invalid-feedback="
                  validationInvalidFeedback(
                    errors.errors,
                    'measurement_types_id'
                  )
                "
                label="Measurement Type"
              >
                <b-form-select
                  v-model="product.measurement_types_id"
                  :disabled="isSaving"
                  :state="
                    validationState(errors.errors, 'measurement_types_id')
                  "
                >
                  <b-form-select-option :value="null"
                  >Select a measurement type
                  </b-form-select-option
                  >
                  <b-form-select-option
                    v-for="(measurement, measurementInd) in measurement_types"
                    :key="`measurement${measurementInd}`"
                    :value="measurement.id"
                  >{{ measurement.name }}
                  </b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                :invalid-feedback="
                  validationInvalidFeedback(errors.errors, 'category_id')
                "
                label="Category"
              >
                <b-form-select
                  v-model="product.category_id"
                  :disabled="isSaving"
                  :state="validationState(errors.errors, 'category_id')"
                >
                  <b-form-select-option :value="null"
                  >Select a category
                  </b-form-select-option
                  >
                  <b-form-select-option
                    v-for="(category, categoryInd) in categories"
                    :key="`category${categoryInd}`"
                    :value="category.id"
                  >{{ category.name }}
                  </b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="$route.name == 'dash.products.create'">
            <b-col cols="12" md="6">
              <b-form-group
                :invalid-feedback="
                  validationInvalidFeedback(errors.errors, 'range')
                "
                label="Range"
              >
                <b-form-input
                  v-model="product.range"
                  :disabled="isSaving"
                  :state="validationState(errors.errors, 'range')"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                :invalid-feedback="
                  validationInvalidFeedback(errors.errors, 'quantity')
                "
                label="Quantity"
              >
                <b-form-input
                  v-model="product.quantity"
                  :disabled="isSaving"
                  :state="validationState(errors.errors, 'quantity')"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-else>
            <b-col cols="12">
              <b-form-group
                :invalid-feedback="
                  validationInvalidFeedback(errors.errors, 'range')
                "
                label="Range"
              >
                <b-form-input
                  v-model="product.range"
                  :disabled="isSaving"
                  :state="validationState(errors.errors, 'range')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'cost_price')"
                            label="Cost Inc Vat">
                <b-form-input
                  v-model="product.cost_price"
                  :disabled="isSaving"
                  :state="validationState(errors.errors, 'cost_price')"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                :invalid-feedback="
                  validationInvalidFeedback(errors.errors, 'sale_price')
                "
                label="Sale Price Inc Vat"
              >
                <b-form-input
                  v-model="product.sale_price"
                  :disabled="isSaving"
                  :state="validationState(errors.errors, 'sale_price')"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <b-form-checkbox
                  v-model="product.create_supply_order"
                  :disabled="isSaving"
                  :state="validationState(errors.errors, 'create_supply_order')"
                  :unchecked-value="0"
                  :value="1"
                >Automatically create Supply Order for this
                  product
                </b-form-checkbox
                >
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col
                    class="d-flex flex-row"
                    cols="12"
                    md="6"
                    role="button"
                    @click.prevent="toggleCollapse('product_variations')"
                  >
                    <b-icon
                      :icon="
                        collapsed['product_variations']
                          ? 'chevron-right'
                          : 'chevron-down'
                      "
                      class="align-self-center cursor-pointer text-muted"
                      font-scale="1.5"
                    ></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Product Variations</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="!collapsed['product_variations']">
                  <b-col cols="12">
                    <hr/>
                  </b-col>
                  <b-col cols="12">
                    <b-card>
                      <b-row>
                        <b-col v-if="isLoading" cols="12">
                          <b-spinner variant="primary"></b-spinner>
                        </b-col>
                        <b-col v-if="!isLoading && product.variations.length">
                          <b-table-simple bordered>
                            <b-thead>
                              <b-tr>
                                <b-th>Name</b-th>
                                <b-th>Width</b-th>
                                <b-th>Quantity</b-th>
                                <b-th class="text-right">Action</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <b-tr
                                v-for="(variation,
                                variationInd) in product.variations"
                                :key="`variation${variation.id}`"
                              >
                                <b-td>
                                  <b-form-group class="mb-0">
                                    <b-form-input
                                      v-model="variation.name"
                                      placeholder="Variation Name"
                                    ></b-form-input>
                                  </b-form-group>
                                </b-td>
                                <b-td>
                                  <b-form-group class="mb-0">
                                    <b-form-input
                                      v-model="variation.width"
                                      placeholder="Width"
                                    ></b-form-input>
                                  </b-form-group>
                                </b-td>
                                <b-td>
                                  <b-form-group class="mb-0">
                                    <b-form-input
                                      v-model="variation.quantity"
                                      placeholder="Quantity"
                                    ></b-form-input>
                                  </b-form-group>
                                </b-td>
                                <b-td class="text-right">
                                  <b-button
                                    :disabled="
                                      !(
                                        !$route.params.id ||
                                        hasAnyPermission([
                                          'delete-product-variations'
                                        ])
                                      )
                                    "
                                    variant="danger"
                                    @click="rmVariation(variationInd)"
                                  >
                                    <fa-icon icon="trash"
                                    />
                                  </b-button>
                                </b-td>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>
                        </b-col>
                        <b-col v-if="!isLoading && !product.variations.length">
                          <p class="text-muted mb-0">
                            There are no product variations defined.
                          </p>
                        </b-col>
                      </b-row>
                      <template #footer>
                        <b-row>
                          <b-col>
                            <b-button
                              block
                              class="float-right"
                              variant="primary"
                              @click="addVariation"
                            >Add Variation
                            </b-button
                            >
                          </b-col>
                        </b-row>
                      </template>
                    </b-card>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col class="d-flex flex-row mt-3" cols="12" md="4" offset-md="8">
              <b-button
                block
                class="align-self-end mb-3"
                type="submit"
                variant="secondary"
              >
                <b-spinner v-if="isSaving" small></b-spinner
                >
                <span v-if="!isSaving">Save</span></b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import validation from "../../mixins/validation";
import {mapActions, mapGetters} from "vuex";
import SupplierSearch from "../../components/SupplierSearch";

export default {
  mixins: [validation],
  components: {SupplierSearch},
  created() {
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id);
    }
    this.fetchManyCategories().then(response => {
      this.categories = response.data;
    });
    this.fetchMeasurementTypes().then(response => {
      this.measurement_types = response.data;
    });
  },
  computed: {
    ...mapGetters("authentication", ["hasAnyPermission"]),
    ...mapGetters("products", ["errors", "isLoading", "isSaving", "single"])
  },
  data() {
    return {
      collapsed: {
        product_variations: true
      },

      product: {
        supplier_id: null,
        supplier: {},
        name: null,
        range: null,
        quantity: 0,
        cost_price: null,
        sale_price: null,
        category_id: null,
        variations: [],
        create_supply_order: null
      },
      categories: [],
      measurement_types: [],

      counter: 0
    };
  },
  methods: {
    ...mapActions("products", ["fetch", "save", "fetchMeasurementTypes"]),
    ...mapActions("categories", {fetchManyCategories: "fetchAll"}),

    submit() {
      let data = {
        id: this.$route.params.id,
        category_id: this.product.category_id,
        supplier_id: this.product.supplier_id,
        name: this.product.name,
        range: this.product.range,
        quantity: this.product.quantity,
        measurement_types_id: this.product.measurement_types_id,
        cost_price: this.product.cost_price,
        sale_price: this.product.sale_price,
        variations: this.product.variations,
        create_supply_order: this.product.create_supply_order
      };

      this.save(data).then(response => {
        if ([200, 201].includes(response.status))
          this.$router.push({
            name: "dash.products.list",
            params: {
              notifications: [
                {
                  variant: "success",
                  title: `Product #${response.data.id}`,
                  subtitle: `${response.data.name}`,
                  text: `You have ${
                    !this.$route.params.id
                      ? "created a new"
                      : "updated an existing"
                  } product.`,
                  timeout: 5000
                }
              ]
            }
          });
      });
    },

    toggleCollapse(name) {
      this.collapsed[name] = !this.collapsed[name];
    },

    supplierUpdated() {
      this.product.supplier_id = this.product.supplier.id;
    },

    addVariation() {
      this.product.variations.push({
        id: "new" + this.counter++,
        name: null,
        quantity: 0,
        width: 0
      });
    },

    rmVariation(variationInd) {
      this.product.variations.splice(variationInd, 1);
    }
  },
  watch: {
    single() {
      if (this.single.categories && this.single.categories.length) {
        this.categoryId = this.single.categories[0].id;
      }
      if (
        this.single.measurement_types &&
        this.single.measurement_types.length
      ) {
        this.measurementId = this.single.measurement_types[0].id;
      }
      this.product = this.single;
    }
  }
};
</script>

<style></style>
