<template>
  <b-container>
    <b-row class="mb-3">
      <b-col cols="6">
        <h1 class="font-weight-bold text-primary">View Product</h1>
      </b-col>
      <b-col cols="6">
        <div class="d-none d-md-flex flex-row justify-content-end pt-2">
          <b-button
            v-b-popover.bottom="'Edit this product.'"
            :to="{ name: 'dash.products.update', params: { id: product.id } }"
            class="mr-1 mb-0"
            size="sm"
            variant="primary">
            <b-icon icon="pen"/>
            Edit Product
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="isLoading">
      <b-col cols="12">
        <p class="text-center">
          <b-spinner variant="secondary"></b-spinner>
        </p>
      </b-col>
    </b-row>

    <b-row v-if="!isLoading">
      <b-col cols="12">
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'supplier_id')" label="Supplier">
              <b-form-input
                v-model="product.supplier.name"
                :state="validationState(errors.errors, 'supplier_id')"
                disabled/>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Category">
              <b-form-select v-model="product.category_id" :state="validationState(errors.errors, 'category_id')"
                             disabled>
                <b-form-select-option :value="product.category.id">{{ product.category.name }}</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'name')" label="Name">
              <b-form-input
                v-model="product.name"
                :state="validationState(errors.errors, 'name')"
                disabled/>
            </b-form-group>
          </b-col>
          <b-col v-if="product.range == null" cols="12" md="6">
            <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'range')" label="Range">
              <b-form-input disabled placeholder="No Range Given"/>
            </b-form-group>
          </b-col>
          <b-col v-else cols="12" md="6">
            <b-form-group
              :invalid-feedback="
                validationInvalidFeedback(errors.errors, 'range')
              "
              label="Range"
            >
              <b-form-input
                v-model="product.range"
                :state="validationState(errors.errors, 'range')"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group
              :invalid-feedback="
                validationInvalidFeedback(errors.errors, 'cost_price')
              "
              label="Cost"
            >
              <b-form-input
                v-model="product.cost_price"
                :state="validationState(errors.errors, 'cost_price')"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group
              :invalid-feedback="
                validationInvalidFeedback(errors.errors, 'sale_price')
              "
              label="Sale Price"
            >
              <b-form-input
                v-model="product.sale_price"
                :state="validationState(errors.errors, 'sale_price')"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Product Variations -->
    <b-row class="mb-4">
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col
              class="d-flex flex-row"
              cols="12"
              md="6"
              role="button"
              @click.prevent="toggleCollapse('product_variations')"
            >
              <b-icon
                :icon="
                  collapsed['product_variations']
                    ? 'chevron-right'
                    : 'chevron-down'
                "
                class="align-self-center cursor-pointer text-muted"
                font-scale="1.5"
              ></b-icon>
              <div class="d-flex flex-column flex-fill ml-4">
                <p class="h4 font-weight-bold mb-0">Product Variations</p>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="!collapsed['product_variations']">
            <b-col cols="12">
              <hr/>
            </b-col>
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col v-if="isLoading" cols="12">
                    <b-spinner variant="primary"></b-spinner>
                  </b-col>
                  <b-col v-if="!isLoading && product.variations.length">
                    <b-table-simple bordered>
                      <b-thead>
                        <b-tr>
                          <b-th>Name</b-th>
                          <b-th>Width</b-th>
                          <b-th>Quantity</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          v-for="variation in product.variations"
                          :key="`variation${variation.id}`"
                        >
                          <b-td>
                            <b-form-group class="mb-0">
                              <b-form-input
                                v-model="variation.name"
                                disabled
                                placeholder="Variation Name"
                              ></b-form-input>
                            </b-form-group>
                          </b-td>
                          <b-td>
                            <b-form-group class="mb-0">
                              <b-form-input
                                v-model="variation.width"
                                disabled
                                placeholder="Width"
                              ></b-form-input>
                            </b-form-group>
                          </b-td>
                          <b-td>
                            <b-form-group class="mb-0">
                              <b-form-input
                                v-model="variation.quantity"
                                disabled
                                placeholder="Quantity"
                              ></b-form-input>
                            </b-form-group>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                  <b-col v-if="!isLoading && !product.variations.length">
                    <p class="text-muted mb-0">
                      There are no product variations defined.
                    </p>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- Transaction Log -->
    <b-row v-if="computedTransactionLog" class="mb-4">
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col class="d-flex flex-row" cols="12" md="6" role="button"
                   @click.prevent="toggleCollapse('transaction_logs')">
              <b-icon :icon="collapsed['transaction_logs'] ? 'chevron-right' : 'chevron-down'"
                      class="align-self-center cursor-pointer text-muted" font-scale="1.5"></b-icon>
              <div class="d-flex flex-column flex-fill ml-4">
                <p class="h4 font-weight-bold mb-0">Transaction Log</p>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="!collapsed['transaction_logs']" class="mt-4">
            <b-col v-if="!product.product_transaction.length" cols="12">
              <b-col class="text-center" cols="12">
                <b-card class="py-3 text-center">
                  <p class="mb-4">
                    <fa-icon class="display-1 text-dark" icon="times"></fa-icon>
                  </p>
                  <p class="font-weight-normal h5 mb-0 text-dark">
                    There are no transactions on this product.
                  </p>
                </b-card>
              </b-col>
            </b-col>
            <b-col v-else cols="12">
              <b-table-simple hover striped>
                <b-thead>
                  <b-tr>
                    <b-th class="text-center">Quantity</b-th>
                    <b-th class="text-center">In / Out</b-th>
                    <b-th class="text-center">Date</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    v-for="(item, itemInd) in product.product_transaction"
                    :key="itemInd"
                  >
                    <b-td class="text-center">{{ item.quantity }}</b-td>
                    <b-td class="text-center">{{ item.status }}</b-td>
                    <b-td class="text-center">{{
                        moment(item.created_at).format("Do MMM YYYY")
                      }}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- Order Activity Log -->
    <b-row v-if="computedAccessLogs" class="mb-4">
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col
              class="d-flex flex-row"
              cols="12"
              md="6"
              role="button"
              @click.prevent="toggleCollapse('access_logs')"
            >
              <b-icon
                :icon="
                  collapsed['access_logs'] ? 'chevron-right' : 'chevron-down'
                "
                class="align-self-center cursor-pointer text-muted"
                font-scale="1.5"
              ></b-icon>
              <div class="d-flex flex-column flex-fill ml-4">
                <p class="h4 font-weight-bold mb-0">Activity Log</p>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="!collapsed['access_logs']" class="mt-4">
            <b-col v-if="!product.access_logs.length" cols="12">
              <b-row>
                <b-col class="text-center" cols="12">
                  <b-card class="py-3 text-center">
                    <p class="mb-4">
                      <fa-icon
                        class="display-1 text-dark"
                        icon="times"
                      ></fa-icon>
                    </p>
                    <p class="font-weight-normal h5 mb-0 text-dark">
                      There are no access logs.
                    </p>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-else cols="12">
              <b-table-simple hover striped>
                <thead>
                <tr>
                  <th>Description</th>
                  <th>User</th>
                  <th>Date</th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="(log, logInd) in product.access_logs"
                  :key="logInd"
                >
                  <td>{{ log.description }}</td>
                  <td>{{ log.users.name }}</td>
                  <td>
                    {{
                      moment(log.created_at).format("Do MMM YYYY, h:mm:ss a")
                    }}
                  </td>
                </tr>
                </tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import validation from "../../mixins/validation";
import {mapActions, mapGetters} from "vuex";
import momentMixin from "../../mixins/momentMixin";

export default {
  mixins: [validation, momentMixin],

  created() {
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id);
    }

    this.fetchManyCategories().then(response => {
      this.categories = response.data.data;
    });

    this.fetchMeasurementTypes().then(response => {
      this.measurement_types = response.data.data;
    });
  },
  computed: {
    ...mapGetters("products", ["errors", "isLoading", "isSaving", "single"]),

    computedAccessLogs() {
      if (this.product.hasOwnProperty("access_logs")) {
        return this.product.access_logs;
      }
      return false;
    },

    computedTransactionLog() {
      if (this.product.hasOwnProperty('product_transaction')) {
        return this.product.product_transaction;
      }
      return false;
    }
  },
  data() {
    return {
      collapsed: {
        product_variations: !this.$route.params.id,
        transaction_log: !this.$route.params.id,
        access_logs: !this.$route.params.id
      },
      product: {
        supplier_id: null,
        supplier: {},
        measurement_types_id: null,
        category_id: null,
        name: null,
        range: null,
        cost_price: null,
        sale_price: null,
        quantity: null,
        supplyOrderItems: [],
        orderItems: [],
        // variations: [],
        product_transaction: [],
        access_logs: [],

        variations: {
          product_transaction: [],
          access_logs: [],
        }
      },
    };
  },
  methods: {
    ...mapActions("products", ["fetch", "save", "fetchMeasurementTypes"]),
    ...mapActions("categories", {fetchManyCategories: "fetchAll"}),


    toggleCollapse(name) {
      this.collapsed[name] = !this.collapsed[name];
    }
  },
  watch: {
    single() {
      if (this.single.categories && this.single.categories.length) {
        this.categoryId = this.single.categories[0].id;
      }

      if (this.single.measurement_types && this.single.measurement_types.length) {
        this.measurementId = this.single.measurement_types[0].id;
      }
      this.product = this.single;
    }
  }
};
</script>

