<template>
  <b-container>
    <ResourceList :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination" :search-query="$route.query.query"
                  can-delete resource="product" soft-deletes
                  title="Low Stock Level Alert" @paginate="onPaginate" @search="onSearch"
                  @delete-many="onDeleteMany">
      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-1 text-primary">
              <router-link :to="{ name: 'dash.products.view', params: { id: item.id } }" class="text-dark">{{ item.name
                }}
              </router-link>
            </p>
            <div class="d-none d-md-flex flex-row">
              <b-button v-b-popover.hover.bottom="'View more information about this quote.'" :to="{ name: 'dash.products.view', params: { id: item.id } }" class="mr-1 mb-0"
                        size="sm"
                        variant="secondary">
                <b-icon icon="file-check"/>
                View
              </b-button>
            </div>
          </div>
          <div class="d-flex flex-row flex-fill mb-0 mb-md-1">
            <p class="mb-0">Remaining Quantity: {{ item.quantity }}</p>
          </div>
        </div>
      </template>
    </ResourceList>
  </b-container>
</template>
<script>
import ResourceList from "../../components/ResourceList"
import currentUser from "../../mixins/currentUser"
import {mapActions, mapGetters} from "vuex"

export default {
  mixins: [currentUser],
  created() {
    this.fetchManyLowStock(this.$route.query)
  },
  components: {
    ResourceList
  },
  computed: {
    ...mapGetters("products", ["data", "isDeleting", "isLoading", "pagination"])
  },
  methods: {
    ...mapActions("products", ["batchDelete", "fetchManyLowStock", "doMakeOrder"]),

    async onDeleteMany({ids, modalId}) {
      await this.batchDelete({
        ids
      })
      this.$bvModal.hide(modalId)
      await this.fetchManyLowStock(this.$route.query)
    },
    async onPaginate(page) {
      if (this.$route.query.page !== page) {
        this.$router.push({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            page
          }
        })
        await this.fetchManyLowStock({
          ...this.$route.query,
          page
        })
      }
    },
    async onSearch(query) {
      if (this.$route.query.query !== query) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            query
          }
        })
        await this.fetchManyLowStock({
          ...this.$route.query,
          query
        })
      }
    },
    makeOrder(quoteId) {
      this.doMakeOrder({...this.$route.query, quoteId}).then(response => {
        // Card Notification
      })
    }
  }
}
</script>
<style></style>
