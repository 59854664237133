<template>
  <b-container>
    <ResourceList :can-delete="hasAnyPermission(['delete-products'])" :data="data"
                  :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination"
                  :search-query="$route.query.query" create-to="dash.products.create"
                  resource="product" soft-deletes title="Products"
                  @paginate="onPaginate" @search="onSearch" @delete-many="onDeleteMany">
      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-0 text-primary">
              <router-link :to="{ name: 'dash.products.view', params: { id: item.id } }" class="text-dark">#{{ item.id
                }} {{ item.name }}
              </router-link>
            </p>
            <p class="align-self-end mb-0 text-muted">Quantity: {{ item.quantity }}</p>
          </div>
          <div class="d-flex flex-row flex-fill">
            <small class="mb-0 text-muted">{{ item.supplier.name }}</small>
          </div>
          <div class="d-flex flex-row flex-fill mt-1">
            <b-button v-b-popover.hover.bottom="'View more information about this product.'"
                      :to="{ name: 'dash.products.view', params: { id: item.id } }" class="mr-1 mb-0"
                      size="sm"
                      variant="secondary">
              <b-icon icon="eye"/>
              View
            </b-button>
            <b-button v-b-popover.hover.bottom="'Edit this product.'"
                      :to="{ name: 'dash.products.update', params: { id: item.id } }" class="mr-1 mb-0" size="sm"
                      variant="primary">
              <b-icon icon="pen"/>
              Edit
            </b-button>
          </div>
        </div>
      </template>

      <template #buttons>
        <b-dd-item-button v-b-modal.product-import-modal>Product Import</b-dd-item-button>
      </template>

    </ResourceList>
    <b-modal id="product-import-modal" centered title="Product Import">
      <template #modal-title>
        <b-row>
          <b-col cols="12">
            <h4 class="mb-0">Product Import</h4>
            <small><a href="/pdf/product-import-template">Template</a></small>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col cols="12">
          <b-form>
            <b-form-group label="Product Import CSV">
              <b-form-file
                v-model="importFile"
                :state="Boolean(importFile)"
                drop-placeholder="Drop file here...."
                placeholder="Upload a product import CSV"
              ></b-form-file>
            </b-form-group>
          </b-form>
        </b-col>
        <b-col v-if="importErrors.length" cols="12">
          <b-alert v-for="(err, errInd) in importErrors" :key="errInd" show variant="danger">
            <div class="d-flex flex-column">
              <h6>An error occurred, no changes were made. Please ensure that the CSV uploadable is valid and try
                again.</h6>
              <p class="mb-0">{{ err }}</p>
            </div>
          </b-alert>
        </b-col>
        <b-col cols="12">
          <b-card>
            <b-row>
              <b-col class="d-flex flex-row" cols="12" md="9" role="button"
                     @click.prevent="toggleCollapse('import_guide')">
                <b-icon :icon="collapsed['import_guide'] ? 'chevron-right' : 'chevron-down'"
                        class="align-self-center cursor-pointer text-muted"
                        font-scale="1.5"></b-icon>
                <div class="d-flex flex-column flex-fill ml-4">
                  <p class="h4 font-weight-bold mb-0">Import Guide</p>
                </div>
              </b-col>
            </b-row>
            <b-row v-if="!collapsed.import_guide">
              <b-col cols="12">
                <hr>
              </b-col>
              <b-col cols="12">
                <b-card no-body>
                  <b-tabs card>
                    <b-tab title="Measurement Types">
                      <b-table-simple>
                        <b-thead>
                          <b-tr>
                            <b-th>Measurement Type</b-th>
                            <b-th class="text-right">Value</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr v-for="measurementType in importGuide.measurement_types"
                                :key="`measurement-type-${measurementType.id}`">
                            <b-td>{{ measurementType.name }}</b-td>
                            <b-td class="text-right">{{ measurementType.id }}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-tab>
                    <b-tab title="Suppliers">
                      <b-table-simple>
                        <b-thead>
                          <b-tr>
                            <b-th>Supplier</b-th>
                            <b-th class="text-right">Value</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr v-for="supplier in importGuide.suppliers" :key="`supplier-${supplier.id}`">
                            <b-td>{{ supplier.name }}</b-td>
                            <b-td class="text-right">{{ supplier.id }}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-tab>
                    <b-tab title="Categories">
                      <b-table-simple>
                        <b-thead>
                          <b-tr>
                            <b-th>Category</b-th>
                            <b-th class="text-right">Value</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr v-for="category in importGuide.product_categories" :key="`category-${category.id}`">
                            <b-td>{{ category.name }}</b-td>
                            <b-td class="text-right">{{ category.id }}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button variant="primary" @click="doProductImport">Import</b-button>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import ResourceList from "../../components/ResourceList"
import currentUser from "../../mixins/currentUser"
import {mapActions, mapGetters} from "vuex"

export default {
  mixins: [currentUser],
  data: () => ({
    importFile: null,
    collapsed: {
      import_guide: true
    },
    importGuide: {
      suppliers: [],
      measurement_types: [],
      product_categories: [],
    },
    importErrors: []
  }),
  created() {
    this.getImportGuideData()
    this.fetchMany(this.$route.query)
  },
  components: {
    ResourceList
  },
  computed: {
    ...mapGetters("authentication", ["hasAnyPermission"]),
    ...mapGetters("products", ["data", "isDeleting", "isLoading", "pagination"])
  },
  methods: {
    ...mapActions("products", ["deleteMany", "fetchMany"]),

    async onDeleteMany({ids, modalId}) {
      await this.deleteMany({ids})
      this.$bvModal.hide(modalId)
      await this.fetchMany(this.$route.query)
    },

    async onPaginate(page) {
      if (this.$route.query.page !== page) {
        await this.$router.replace({name: this.$route.name, query: {...this.$route.query, page}}).catch(() => {
        });
        await this.fetchMany({...this.$route.query, page}).catch(() => {
        });
      }
    },
    async onSearch(query) {
      if (this.$route.query.page !== query) {
        const path = {name: this.$route.name, query: {...this.$route.query, query}};
        await this.$router.push(path).catch(() => {
        });
      }
      await this.fetchMany({...this.$route.query, query}).catch(() => {
      })
    },

    doPaginate() {
      this.fetchMany(this.$route.query)
    },

    toggleCollapse(name) {
      this.collapsed[name] = !this.collapsed[name]
    },

    getImportGuideData() {
      window.axios.get('/products/import-guide-data')
        .then(response => {
          this.importGuide = response.data
        })
    },

    doProductImport() {
      if (this.importFile) {
        var formData = new FormData();
        formData.append('csv', this.importFile);

        window.axios.post('/products/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          this.$root.$emit('bv::hide::modal', 'product-import-modal')
          this.importFile = null
          this.importErrors = []
          this.doPaginate()
        }).catch(err => {
          this.importErrors = err.response.data.csv
        })
      }
    }
  }
}
</script>
<style>
</style>
